<!--
File: TrafficSiteEditForm.vue
Description: from for adding/editing one traffic site info.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
              @input='onRegionChange' :is_required="true" />
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-30">
            <RoadsDropdown :class="[{ 'md-valid': !errors.has('fk_road') }, { 'md-error': errors.has('fk_road') }]"
              :label="$t('label.select_road')" v-model="fk_road" data-vv-name="fk_road"
              v-validate="modelValidations.fk_road" :initial_value="fk_road" :is_required="true"
              :region_id="selectedRegion" />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-10.0 md-size-30">
            <SectionsDropDown
              :class="[{ 'md-valid': !errors.has('fk_section') }, { 'md-error': errors.has('fk_section') }]"
              :label="$t('label.select_section')" v-model="fk_section" data-vv-name="fk_section"
              v-validate="modelValidations.fk_section" :initial_value="fk_section" :road_id="fk_road" />
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-30">
            <md-field :class="[
              { 'md-valid': !errors.has('traffic_site_desc') }, { 'md-error': errors.has('traffic_site_desc') }
            ]">
              <label>{{ $t('stdCols.description') }}</label>
              <md-input v-model="traffic_site_desc" type="text" data-vv-name="traffic_site_desc" required
                v-validate="modelValidations.traffic_site_desc" />
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[
              { 'md-valid': !errors.has('start_distance') }, { 'md-error': errors.has('start_distance') }
            ]">
              <label>{{ $t('road_network.start_distance') }}</label>
              <md-input v-model="start_distance" type="text" data-vv-name="start_distance" required
                v-validate="modelValidations.start_distance" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-15">
            <md-field :class="[
              { 'md-valid': !errors.has('end_distance') }, { 'md-error': errors.has('end_distance') }
            ]">
              <label>{{ $t('road_network.end_distance') }}</label>
              <md-input v-model="end_distance" type="text" data-vv-name="end_distance" required
                v-validate="modelValidations.end_distance" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[
              { 'md-valid': !errors.has('surveyed_distance') }, { 'md-error': errors.has('surveyed_distance') }
            ]">
              <label>{{ $t('traffic.surveyed_distance') }}</label>
              <md-input v-model="surveyed_distance" type="text" data-vv-name="surveyed_distance" required
                v-validate="modelValidations.surveyed_distance" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>{{ $t('traffic.sdr_distance') }}</label>
              <md-input v-model="sdr_distance" type="Number" data-vv-name="sdr_distance" />
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-20 md-size-30">
            <BaseDropdown :label="$t('traffic.aadt_coef_set')" v-model="fk_aadt_coef_set" :items="coeffSetsList"
              :displayField="'name'" :valueField="'id'" />
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import Swal from 'sweetalert2'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
import SectionsDropDown from '../Dropdowns/SectionsDropdown.vue'
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import { onClose } from '@/mixins/onCloseMixin'

//Delet after Andrey implements new api in the issue #88
const aadt_coeff_sets = [
  {
    coef_set_id: 1,
    description: 'ГОСТ 32965—2014 (Перегоны)',
    description_en: 'GOST 32965—2014 (Main sections)'
  }, {
    coef_set_id: 2,
    description: 'ГОСТ 32965—2014 (Подходы к населенным пунктам)',
    description_en: 'GOST 32965—2014 (Approaches to settlements)'
  }
]

export default {
  name: 'traffic-sites-edit',
  filters: {
    dateFormat: function (value) {
      if (!value) return ''
      value = value.toString().substring(0, 19)
      return value
    }
  },
  data() {
    return {
      traffic_site_id: null,
      selectedRegion: null,
      fk_road: null,
      fk_section: null,
      traffic_site_desc: null,
      start_distance: null,
      end_distance: null,
      fk_aadt_coef_set: null,
      ts_guid: null,
      fk_vclass_preset: null,
      surveyed_distance: null,
      sdr_distance: null,
      geom: null,
      sdr_road: null,

      modelValidations: {
        fk_road: { required: true, numeric: true, min_value: 1 },
        fk_section: { required: true, numeric: true, min_value: 1 },
        traffic_site_desc: { required: true, min: 3 },
        start_distance: { required: true, numeric: true },
        end_distance: { required: true, numeric: true },
        surveyed_distance: { required: true, numeric: true },
      },
    }
  },
  components: {
    RegionsDropdown,
    RoadsDropdown,
    SectionsDropDown,
    BaseDropdown
  },
  props: {
    oper: String
  },
  mounted() {
    const { tid = null } = this.$route.params
    this.traffic_site_id = tid
    if (this.oper === 'upd' && tid) {
      this.$store.dispatch('LOAD_TRAFFIC_SITE_BY_ID', tid).then((res) => {
        const theSite = Array.isArray(res) ? res[0] : res

        this.fk_road = theSite.fk_road
        this.$store.dispatch('GET_REGION_ROAD_FROM_SECTION', theSite.fk_section).then(
          (res) => {
            this.selectedRegion = res.fk_region
            if (!this.fk_road) this.fk_road = res.fk_road
          },
          (err) => {
            this.selectedRegion = 0
          }
        )

        this.fk_section = theSite.fk_section
        this.traffic_site_desc = theSite.traffic_site_desc
        this.start_distance = theSite.start_distance
        this.end_distance = theSite.end_distance
        this.fk_aadt_coef_set = theSite.fk_aadt_coef_set
        this.ts_guid = theSite.ts_guid
        this.fk_vclass_preset = theSite.fk_vclass_preset
        this.surveyed_distance = theSite.surveyed_distance
        this.sdr_distance = theSite.sdr_distance
        this.geom = theSite.geom
        this.sdr_road = theSite.sdr_road
      })
    }
    this.$nextTick(() => {
      this.$validator.validateAll()
    })
  },

  methods: {
    onClose/*() {
      this.$store.dispatch('SET_USE_HISTORY')
      this.$router.push('/inventory_data/traffic')
    }*/,
    onRegionChange() {
    },
    async validate() {
      this.$validator.validateAll().then((isValid) => {
        const alert = {
          icon: 'error',
          text: this.$t(`traffic.traffic_site`) + this.$t(`label.was_not_saved`, {
            name_ru: this.traffic_site_desc,
            name_en: this.traffic_site_desc
          }),
          footer: ''
        }

        if (isValid) {
          const theSite = {
            fk_road: this.fk_road,
            fk_section: this.fk_section,
            traffic_site_desc: this.traffic_site_desc,
            start_distance: this.start_distance,
            end_distance: this.end_distance,
            fk_aadt_coef_set: this.fk_aadt_coef_set,
            ts_guid: this.ts_guid,
            fk_vclass_preset: this.fk_vclass_preset,
            surveyed_distance: this.surveyed_distance,
            sdr_distance: this.sdr_distance,
            //geom: this.geom,
            sdr_road: this.sdr_road
          }
          const reqData = this.oper === 'add' ? theSite : { id: this.traffic_site_id, traffic_site: theSite }
          this.$store.dispatch(`${this.oper.toUpperCase()}_TRAFFIC_SITE`, reqData).then(
            () => {
              alert.icon = 'success'
              alert.text = this.$t('traffic.traffic_site') + this.$t(`label.was_saved`, {
                name_ru: this.traffic_site_desc,
                name_en: this.traffic_site_desc
              })
              Swal.fire(alert).then(() => {
                this.$nextTick(() => this.$validator.reset())
                return
              })
            },
            (err) => {
              alert.footer = err
            }
          )
        } else {
          alert.footer = this.$t('messages.errors_count', { count: this.errors.items.length })
        }
        Swal.fire(alert)
      })
      // To return back to the list
      this.$router.push('/inventory_data/traffic')
    }
  },

  computed: {
    saveBtnDisabled() {
      return this.errors.items.length > 0
    },
    coeffSetsList() {
      const lang = global.localStorage["mps-locale"]

      return aadt_coeff_sets.map(item => ({
        id: item.coef_set_id,
        name: lang === 'en' ? item.description_en : item.description
      }))
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>