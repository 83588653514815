var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-30"},[_c('RegionsDropdown',{attrs:{"label":_vm.$t('label.select_region'),"initial_value":_vm.selectedRegion,"is_required":true},on:{"input":_vm.onRegionChange},model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}})],1),_c('div',{staticClass:"md-layout-item md-small-size-10.0 md-size-30"},[_c('RoadsDropdown',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.fk_road),expression:"modelValidations.fk_road"}],class:[{ 'md-valid': !_vm.errors.has('fk_road') }, { 'md-error': _vm.errors.has('fk_road') }],attrs:{"label":_vm.$t('label.select_road'),"data-vv-name":"fk_road","initial_value":_vm.fk_road,"is_required":true,"region_id":_vm.selectedRegion},model:{value:(_vm.fk_road),callback:function ($$v) {_vm.fk_road=$$v},expression:"fk_road"}})],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-10.0 md-size-30"},[_c('SectionsDropDown',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.fk_section),expression:"modelValidations.fk_section"}],class:[{ 'md-valid': !_vm.errors.has('fk_section') }, { 'md-error': _vm.errors.has('fk_section') }],attrs:{"label":_vm.$t('label.select_section'),"data-vv-name":"fk_section","initial_value":_vm.fk_section,"road_id":_vm.fk_road},model:{value:(_vm.fk_section),callback:function ($$v) {_vm.fk_section=$$v},expression:"fk_section"}})],1),_c('div',{staticClass:"md-layout-item md-small-size-10.0 md-size-30"},[_c('md-field',{class:[
            { 'md-valid': !_vm.errors.has('traffic_site_desc') }, { 'md-error': _vm.errors.has('traffic_site_desc') }
          ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.description')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.traffic_site_desc),expression:"modelValidations.traffic_site_desc"}],attrs:{"type":"text","data-vv-name":"traffic_site_desc","required":""},model:{value:(_vm.traffic_site_desc),callback:function ($$v) {_vm.traffic_site_desc=$$v},expression:"traffic_site_desc"}})],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('md-field',{class:[
            { 'md-valid': !_vm.errors.has('start_distance') }, { 'md-error': _vm.errors.has('start_distance') }
          ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.start_distance')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.start_distance),expression:"modelValidations.start_distance"}],attrs:{"type":"text","data-vv-name":"start_distance","required":""},model:{value:(_vm.start_distance),callback:function ($$v) {_vm.start_distance=$$v},expression:"start_distance"}})],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-10.0 md-size-15"},[_c('md-field',{class:[
            { 'md-valid': !_vm.errors.has('end_distance') }, { 'md-error': _vm.errors.has('end_distance') }
          ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.end_distance')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.end_distance),expression:"modelValidations.end_distance"}],attrs:{"type":"text","data-vv-name":"end_distance","required":""},model:{value:(_vm.end_distance),callback:function ($$v) {_vm.end_distance=$$v},expression:"end_distance"}})],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('md-field',{class:[
            { 'md-valid': !_vm.errors.has('surveyed_distance') }, { 'md-error': _vm.errors.has('surveyed_distance') }
          ]},[_c('label',[_vm._v(_vm._s(_vm.$t('traffic.surveyed_distance')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.surveyed_distance),expression:"modelValidations.surveyed_distance"}],attrs:{"type":"text","data-vv-name":"surveyed_distance","required":""},model:{value:(_vm.surveyed_distance),callback:function ($$v) {_vm.surveyed_distance=$$v},expression:"surveyed_distance"}})],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t('traffic.sdr_distance')))]),_c('md-input',{attrs:{"type":"Number","data-vv-name":"sdr_distance"},model:{value:(_vm.sdr_distance),callback:function ($$v) {_vm.sdr_distance=$$v},expression:"sdr_distance"}})],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-20 md-size-30"},[_c('BaseDropdown',{attrs:{"label":_vm.$t('traffic.aadt_coef_set'),"items":_vm.coeffSetsList,"displayField":'name',"valueField":'id'},model:{value:(_vm.fk_aadt_coef_set),callback:function ($$v) {_vm.fk_aadt_coef_set=$$v},expression:"fk_aadt_coef_set"}})],1),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit","disabled":_vm.saveBtnDisabled},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),_c('md-button',{staticClass:"md-accent",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClose.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }