<!--
File: SectionsDropdown.vue
Description: show the dropdown combo with the road sections list.
-->
<template>
  <md-field class="sections-select">
    <label for="section_id">{{ label }}</label>
    <md-select v-model.number='section_id' name="section_id" id="section_id" :disabled="disabled" :required="is_required"
      @md-selected='onSectionChange' >
      <md-option v-for='(sid, ind) in sectionsList' :key='ind' :value='sid.section_id'>
        {{ sid.section_description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'sections-dropdown',

    props: {
      label: { default: "", type: String },
      region_id: { default: null, type: Number },
      road_id: { default: null, type: Number },
      initial_value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        section_id: this.initial_value,
      }
    },

    mounted() {
      //this.section_id = this.initial_value
    },

    methods: {
      reload() {
        const values = { 
          region_id: this.region_id,
          road_id: this.road_id,
          show_all: !this.is_required
        }
        this.$store.dispatch('GET_SECTIONS_DROPDOWN', values).then(() => {
          if (this.initial_value && !this.section_id) {
            this.section_id = this.initial_value;
          }
        })
      },
      onSectionChange() {
        const findSectionId = (!this.section_id || this.section_id == null) ? '' : 
          this.sectionsList.find((element) => element.section_id == this.section_id)

        const sectionDesc = (!findSectionId) ? '' : findSectionId.section_description
        this.$emit('input', this.section_id, sectionDesc)
      },
    },

    computed: {
      sectionsList() {
        return this.$store.state.Dropdowns.sections;
      }
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.deuListLoaded) {
          this.deu_id = newValue;
        }
      },
      region_id(value) {
        this.section_id = null
        this.reload()
      },
      road_id(value) {
        this.section_id = null
        this.reload()
      },
    }
  }
</script>
<style lang="scss" scoped>
.sections-select {
  width: 100%;
  display: inline-block;
  overflow: visible;
}
</style>
  